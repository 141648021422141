<template>
  <div class="shopify-connection-page">
    <div class="auth-head">
      <div class="auth-head__logo">
        <router-link :to="$store.getters.GET_PATHS.login">
          <img src="/img/login-group/login-logo.png" alt="alt">
        </router-link>
      </div>
    </div>

    <div class="shopify-connection">
      <div class="shopify-connection__inner">
        <div class="shopify-connection__left">
          <img src="/img/login-group/shopify-logo.svg" alt="alt">
        </div>
        <div class="shopify-connection__right">
          <div class="shopify-connection__title">
            {{ $t('common_shopifyConnectionTitle.localization_value.value') }}
          </div>
          <div class="shopify-connection__subtitle">
            {{ $t('common_shopifyConnectionSubTitle.localization_value.value') }}
            {{ $t('common_shopifyConnectionSubTitle2.localization_value.value') }}
          </div>
          <div class="shopify-connection__btns">
            <MainButton
                class="shopify-connection__btn"
                :value="$t('common_shopifyConnection.localization_value.value')"
                @click.native="goToConnection"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="shopify-connection-decs">
      <div class="shopify-connection-decs__title">
        {{ $t('common_shopifyConnectionDescTitle.localization_value.value') }}
      </div>
      <div class="shopify-connection-decs__list">
          {{ $t('common_shopifyConnectionDesc1.localization_value.value') }}
        <br>
        <br>
          {{ $t('common_shopifyConnectionDesc2.localization_value.value') }}
          {{ $t('common_shopifyConnectionDesc3.localization_value.value') }}
        <br>
        <br>
          {{ $t('common_shopifyConnectionDesc4.localization_value.value') }}
          {{ $t('common_shopifyConnectionDesc5.localization_value.value') }}
        <br>
        <br>
          {{ $t('common_shopifyConnectionDesc6.localization_value.value') }}
          {{ $t('common_shopifyConnectionDesc7.localization_value.value') }}
      </div>
    </div>

    <div class="shopify-connection-page__footer">
      Copyright © 2023 SkladUSA
    </div>

  </div>
</template>

<script>
  import {mixinDetictingMobile} from "../../../../../mixins/mobileFunctions";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";

  export default {
    name: "GetPdfFile",

    components: {
      MainButton

    },

    mixins: [mixinDetictingMobile],

    data() {
      return {
        loadingFile: true,
        fileBase64: null,
      }
    },
    //
    // beforeCreate() {
    //   document.title = this.$t('common_getFileTitle.localization_value.value');
    // },

    mounted() {
      let urlRedirected = localStorage.getItem('urlRedirectedFromUnauthorizedPage')
      if(urlRedirected.indexOf(this.$store.getters.GET_PATHS.shopifyInfoPage) > -1) {
        urlRedirected = urlRedirected.replace(this.$store.getters.GET_PATHS.shopifyInfoPage, this.$store.getters.GET_PATHS.shopifyConnection)
        localStorage.setItem('urlRedirectedFromUnauthorizedPage', urlRedirected)
      }
    },

    methods: {

      goToConnection() {
        let query = this.$route.query
        this.$router.push({path: this.$store.getters.GET_PATHS.shopifyConnection, query: query})
      }

    },

  }
</script>

<style scoped lang="scss">

.shopify-connection {
  padding-top: 65px;
  padding-bottom: 100px;
  border-bottom: 1px solid #a9a9a9;

  &__inner {
    display: flex;
    max-width: 1080px;
    margin: 0 auto;
  }

  &__left {
    display: flex;
    align-items: center;
    width: 50%;

    img {
      max-width: 350px;
    }
  }

  &__right {
    width: 50%;
  }

  &__title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 30px;
  }

  &__btns {

  }

  &__btn {
    height: 56px;
    max-width: 200px;
  }

}

.shopify-connection-decs {
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;

  &__title {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.32;
    margin-bottom: 30px;
  }

  &__list {
    padding-left: 0;

    li {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }

  &__list-name {
    font-size: 20px;
    font-weight: bold;
  }

}

.shopify-connection-page {

  &__footer {
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    background: #55555b;
    color: white;
  }
}
</style>
